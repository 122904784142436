/**
 * 팝업 HTML을 생성합니다.
 */
export function generatePopupHtml(combinedSvgString) {
  return `
    <div>
      <!-- 닫기 버튼 추가 -->
      <div style="position: absolute; top: 20px; right: 20px; z-index: 1000;">
        <button tabindex="-1" class="reset-cutfile-btn" style="padding: 8px 16px; background: #fff; border: 1px solid #ddd; border-radius: 4px; cursor: pointer; margin-right: 10px; transition: background-color 0.2s;" onmouseover="this.style.backgroundColor='#fff3cd'" onmouseout="this.style.backgroundColor='#fff'">초기화</button>
        <button tabindex="-1" class="custom-close-btn" style="padding: 8px 16px; background: #fff; border: 1px solid #ddd; border-radius: 4px; cursor: pointer; transition: background-color 0.2s;" onmouseover="this.style.backgroundColor='#ffe6e6'" onmouseout="this.style.backgroundColor='#fff'">닫기</button>
      </div>

      <div style="display: flex; justify-content: space-between; gap: 20px; margin-bottom: 20px;">
        <!-- 좌측: Combined SVG -->
        <div style="flex: 1; text-align: center;">
          <h3>미리보기</h3>
          <div id="combined-svg-container" class="image-container" style="position: relative; display: inline-block; font-size: 0; line-height: 0;">${combinedSvgString}</div>
        </div>
        
        <!-- 우측: Zoom View -->
        <div style="flex: 1; text-align: center;">
          <h3>확대보기</h3>
          <div style="position: relative;">
            <canvas class="zoom-canvas combined-zoom" width="600" height="600" style="border: 1px solid #ddd;"></canvas>
            <div style="position: absolute; top: 10px; left: 50%; transform: translateX(-50%); display: flex; gap: 5px;">
              <button tabindex="-1" id="zoom-toggle" class="zoom-toggle" style="padding: 5px 10px; background: #fff; border: 1px solid #ddd; border-radius: 4px; cursor: pointer; z-index: 999999;">R</button>
              <button tabindex="-1" id="capture-btn" class="capture-btn" style="padding: 5px 10px; background: #fff; border: 1px solid #ddd; border-radius: 4px; cursor: pointer; z-index: 999999;">C</button>
              <button tabindex="-1" id="edit-cut-btn" class="edit-cut-btn" style="padding: 5px 10px; background: #fff; border: 1px solid #ddd; border-radius: 4px; cursor: pointer; z-index: 999999;">F</button>
              <button tabindex="-1" id="upload-btn" class="upload-btn" style="padding: 5px 10px; background: #fff; border: 1px solid #ddd; border-radius: 4px; cursor: pointer; z-index: 999999;">U</button>
            </div>
          </div>
        </div>
      </div>

      <!-- 하단: 버튼 섹션 -->
      <div style="border-top: 1px solid #ddd; padding-top: 20px;">
        <div style="display: flex; justify-content: space-between; gap: 40px;">

          <!-- 다운로드 섹션 -->
          <div style="flex: 1;">
            <h4>다운로드</h4>
            <div style="display: flex; gap: 10px; align-items: center; justify-content: center;">
              <button class="swal2-confirm swal2-styled download-printable"
                style="width: 200px; background-color: #2196F3; border: none; color: white; padding: 10px; border-radius: 4px; cursor: pointer; transition: all 0.3s ease;
                       box-shadow: 0 2px 4px rgba(0,0,0,0.2);"
                onmouseover="this.style.backgroundColor='#1976D2'; this.style.transform='translateY(-2px)'"
                onmouseout="this.style.backgroundColor='#2196F3'; this.style.transform='translateY(0)'"
                onmousedown="this.style.backgroundColor='#1565C0'; this.style.transform='translateY(1px)'; this.style.boxShadow='0 1px 2px rgba(0,0,0,0.2)'"
                onmouseup="this.style.backgroundColor='#2196F3'; this.style.transform='translateY(0)'; this.style.boxShadow='0 2px 4px rgba(0,0,0,0.2)'">
                디자인 다운로드
              </button>
              <button class="swal2-confirm swal2-styled download-cutting"
                style="width: 200px; background-color: #2196F3; border: none; color: white; padding: 10px; border-radius: 4px; cursor: pointer; transition: all 0.3s ease;
                       box-shadow: 0 2px 4px rgba(0,0,0,0.2);"
                onmouseover="this.style.backgroundColor='#1976D2'; this.style.transform='translateY(-2px)'"
                onmouseout="this.style.backgroundColor='#2196F3'; this.style.transform='translateY(0)'"
                onmousedown="this.style.backgroundColor='#1565C0'; this.style.transform='translateY(1px)'; this.style.boxShadow='0 1px 2px rgba(0,0,0,0.2)'"
                onmouseup="this.style.backgroundColor='#2196F3'; this.style.transform='translateY(0)'; this.style.boxShadow='0 2px 4px rgba(0,0,0,0.2)'">
                칼선 다운로드
              </button>
              <button class="swal2-confirm swal2-styled download-combined"
                style="width: 200px; background-color: #2196F3; border: none; color: white; padding: 10px; border-radius: 4px; cursor: pointer; transition: all 0.3s ease;
                       box-shadow: 0 2px 4px rgba(0,0,0,0.2);"
                onmouseover="this.style.backgroundColor='#1976D2'; this.style.transform='translateY(-2px)'"
                onmouseout="this.style.backgroundColor='#2196F3'; this.style.transform='translateY(0)'"
                onmousedown="this.style.backgroundColor='#1565C0'; this.style.transform='translateY(1px)'; this.style.boxShadow='0 1px 2px rgba(0,0,0,0.2)'"
                onmouseup="this.style.backgroundColor='#2196F3'; this.style.transform='translateY(0)'; this.style.boxShadow='0 2px 4px rgba(0,0,0,0.2)'">
                겹쳐서 다운로드
              </button>
            </div>
          </div>

          <!-- 업로드 섹션 -->
          <div style="flex: 1;">
            <h4>업로드</h4>
            <div style="display: flex; gap: 10px; align-items: center; justify-content: center;">
              <button class="swal2-confirm swal2-styled upload-cutting"
                style="width: 200px; background-color: #FF5722; border: none; color: white; padding: 10px; border-radius: 4px; cursor: pointer; transition: all 0.3s ease;
                       box-shadow: 0 2px 4px rgba(0,0,0,0.2);"
                onmouseover="this.style.backgroundColor='#F4511E'; this.style.transform='translateY(-2px)'"
                onmouseout="this.style.backgroundColor='#FF5722'; this.style.transform='translateY(0)'"
                onmousedown="this.style.backgroundColor='#E64A19'; this.style.transform='translateY(1px)'; this.style.boxShadow='0 1px 2px rgba(0,0,0,0.2)'"
                onmouseup="this.style.backgroundColor='#FF5722'; this.style.transform='translateY(0)'; this.style.boxShadow='0 2px 4px rgba(0,0,0,0.2)'">
                칼선 반영하기
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  `;
}
