import Swal from 'sweetalert2';
import { UtilAlertF } from '../../../../../Util/Alert/F/Function/module/UtilAlertF.js';
import { UtilF } from '../../../../../Util/F/Function/module/UtilF.js';
import { clearLastEditedSvg, getLastEditedSvg } from '../cutlineVectorEditor/eventHandlers.js';
import { downloadSvgFileFromSvgEl, handleFileUpload } from '../utils/file.js';
import { downloadSvgAsPng } from '../utils/image.js';
import { removePaddingFromSvg } from '../utils/vector.js';
import { closeUploadDialog, showUploadDialog } from './uploadDialog.js';

/**
 * 이벤트 핸들러를 설정합니다.
 */
export function setupEventHandlers(dom, { svg_product_page_id, up_id, addedPadding }) {
  // ESC 키 이벤트 핸들러
  const handleEscKey = (event) => {
    if (event.key === 'Escape') {
      dom.querySelector('.custom-close-btn')?.click();
    }
  };
  dom.addEventListener('keydown', handleEscKey);

  // 'u' 키 이벤트 핸들러
  const handleUploadKey = (event) => {
    if ((event.key.toLowerCase() === 'u' || event.key === 'ㅕ') && !event.ctrlKey && !event.metaKey) {
      event.preventDefault();
      const uploadButton = dom.querySelector('.upload-cutting');
      if (uploadButton instanceof HTMLElement) {
        uploadButton.click();
      }
    }
  };
  dom.addEventListener('keydown', handleUploadKey);

  // 초기화 버튼 이벤트 핸들러
  dom.querySelector('.reset-cutfile-btn')?.addEventListener('click', async () => {
    try {
      const result = await $.confirm('칼선을 초기 상태로 되돌리시겠습니까?');

      if (!result) {
        return;
      }

      $.don_loader_start();
      const response = await UtilF.apiCall({
        url: '/@api/svg_product_page/reset_cutfile',
        method: 'PUT',
        data: { svg_product_page_id },
      });

      if (response.success) {
        await UtilAlertF.success({
          title: '초기화 완료',
          msg: '칼선이 초기 상태로 되돌아갔습니다.',
        });
      }
    } catch (error) {
      console.error('[resetCutfile] Error:', error);

      // API 응답에서 에러 메시지 추출
      const errorMessage = error.response?.data?.message || '칼선 초기화 중 오류가 발생했습니다.';
      const errorCode = error.response?.data?.code || 'UNKNOWN_ERROR';

      // 에러 코드에 따른 사용자 친화적인 메시지
      let userMessage = errorMessage;
      switch (errorCode) {
        case 'PAGE_NOT_FOUND':
          userMessage = '칼선 페이지 정보를 찾을 수 없습니다.';
          break;
        case 'INTERNAL_SERVER_ERROR':
          userMessage = '칼선 초기화 중 서버 오류가 발생했습니다. 잠시 후 다시 시도해주세요.';
          break;
      }

      await UtilAlertF.error({
        title: '초기화 실패',
        msg: userMessage,
      });
    } finally {
      $.don_loader_end();
    }
  });

  // 커스텀 닫기 버튼 이벤트 핸들러
  dom.querySelector('.custom-close-btn')?.addEventListener('click', async () => {
    const lastEditedSvg = getLastEditedSvg();

    if (lastEditedSvg) {
      const result = await $.confirm('저장하지 않은 변경사항이 있습니다. 정말 닫으시겠습니까?');

      if (!result) {
        return;
      }
    }

    // ESC 키 이벤트 리스너 제거
    document.removeEventListener('keydown', handleEscKey);
    clearLastEditedSvg();
    Swal.close();
  });

  /**
   * combined-svg-container에서 SVG 요소를 찾아 반환합니다.
   * @returns {SVGSVGElement | null}
   */
  const getCombinedSvg = () => {
    const container = dom.querySelector('#combined-svg-container');
    if (!container) return null;
    return container.querySelector('svg');
  };

  /**
   * SVG 요소를 복제하고 이미지 요소를 처리합니다.
   * @param {SVGSVGElement} svgElement - 원본 SVG 요소
   * @param {'remove' | 'keep' | 'only'} imageMode - 이미지 처리 모드
   * @returns 처리된 SVG 요소
   */
  const processSvgWithImage = (svgElement, imageMode) => {
    const clonedSvg = /** @type {SVGSVGElement} */ (svgElement.cloneNode(true));

    // SVG 문자열로 변환하고 padding 제거
    const serializer = new XMLSerializer();
    const svgString = serializer.serializeToString(clonedSvg);
    const processedSvgString = removePaddingFromSvg(svgString, addedPadding);

    // 처리된 SVG 문자열을 다시 DOM 요소로 변환
    const parser = new DOMParser();
    const processedSvg = parser.parseFromString(processedSvgString, 'image/svg+xml').documentElement;

    // 이미지 모드에 따른 처리
    const images = processedSvg.querySelectorAll('image');
    if (imageMode === 'remove') {
      images.forEach((image) => image.remove());
    } else if (imageMode === 'only') {
      const nonImageElements = processedSvg.querySelectorAll(':not(image)');
      nonImageElements.forEach((element) => {
        if (element !== processedSvg) {
          element.remove();
        }
      });
    }

    return processedSvg;
  };

  // 디자인 + 칼선 다운로드
  dom.querySelector('.download-combined')?.addEventListener('click', async () => {
    const name = '디자인-칼선';
    try {
      const svgElement = getCombinedSvg();
      if (!svgElement) {
        await UtilAlertF.error({
          title: '다운로드 실패',
          msg: 'SVG 요소를 찾을 수 없습니다.',
        });
        return;
      }

      const processedSvg = processSvgWithImage(svgElement, 'keep');
      await downloadSvgFileFromSvgEl(processedSvg, `${name}-${up_id}.svg`);
    } catch (err) {
      console.error(err);
      await UtilAlertF.error({ title: 'Error', msg: `${name} 파일 다운로드 중 오류가 발생했습니다.` });
    }
  });

  // 디자인 파일 다운로드
  dom.querySelector('.download-printable')?.addEventListener('click', async () => {
    const name = '디자인';
    try {
      const svgElement = getCombinedSvg();
      if (!svgElement) {
        await UtilAlertF.error({
          title: '다운로드 실패',
          msg: 'SVG 요소를 찾을 수 없습니다.',
        });
        return;
      }

      const processedSvg = processSvgWithImage(svgElement, 'only');
      const svgString = new XMLSerializer().serializeToString(processedSvg);

      // SVG의 viewBox 속성에서 너비와 높이 추출
      const viewBox = processedSvg.getAttribute('viewBox')?.split(' ') || [];
      const width = parseFloat(viewBox[2] || processedSvg.getAttribute('width') || '0');
      const height = parseFloat(viewBox[3] || processedSvg.getAttribute('height') || '0');

      if (!width || !height) {
        throw new Error('SVG 크기를 확인할 수 없습니다.');
      }

      // PNG로 변환하여 다운로드
      await downloadSvgAsPng(
        {
          svgString,
          width,
          height,
          scale: 1,
          dpi: 300,
        },
        `${name}-${up_id}.png`,
      );
    } catch (err) {
      console.error(err);
      await UtilAlertF.error({ title: 'Error', msg: `${name} 파일 다운로드 중 오류가 발생했습니다.` });
    }
  });

  // 칼선 파일 다운로드
  dom.querySelector('.download-cutting')?.addEventListener('click', async () => {
    const name = '칼선';
    try {
      const svgElement = getCombinedSvg();
      if (!svgElement) {
        await UtilAlertF.error({
          title: '다운로드 실패',
          msg: 'SVG 요소를 찾을 수 없습니다.',
        });
        return;
      }

      const processedSvg = processSvgWithImage(svgElement, 'remove');
      await downloadSvgFileFromSvgEl(processedSvg, `${name}-${up_id}.svg`);
    } catch (err) {
      console.error(err);
      await UtilAlertF.error({ title: 'Error', msg: `${name} 파일 다운로드 중 오류가 발생했습니다.` });
    }
  });

  // 칼선 파일 업로드
  dom.querySelector('.upload-cutting')?.addEventListener('click', async () => {
    // 마지막으로 편집된 SVG가 있는지 확인
    const lastEditedSvg = getLastEditedSvg();

    const handleCuttingFileUpload = async (file) => {
      try {
        $.don_loader_start();
        const formData = new FormData();
        formData.append('file', file);
        formData.append('svg_product_page_id', svg_product_page_id);
        formData.append('dpi', '300');

        const response = await UtilF.apiCall({
          url: '/@api/svg_product_page/replace_cutfile',
          method: 'POST',
          data: formData,
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });

        if (response.url) {
          await UtilAlertF.success({ title: '업로드 완료', msg: '칼선 파일이 업로드되었습니다.' });
          closeUploadDialog(); // 성공 시 다이얼로그 닫기
        } else {
          throw new Error('칼선 파일 업로드가 정상적으로 완료되지 않았습니다.');
        }
      } catch (error) {
        $.don_loader_end();
        console.error('[handleCuttingFileUpload] Error:', error);

        // API 응답에서 에러 메시지 추출
        const errorMessage = error.response?.data?.message || '칼선 파일 업로드 중 오류가 발생했습니다.';
        const errorCode = error.response?.data?.code || 'UNKNOWN_ERROR';

        // 에러 코드에 따른 사용자 친화적인 메시지
        let userMessage = errorMessage;
        switch (errorCode) {
          case 'FILE_NOT_PROVIDED':
            userMessage = 'SVG 파일을 선택해주세요.';
            break;
          case 'PAGE_NOT_FOUND':
            userMessage = '페이지 정보를 찾을 수 없습니다.';
            break;
          case 'INVALID_FILENAME_FORMAT':
            userMessage = '잘못된 파일 이름 형식입니다.';
            break;
          case 'S3_UPLOAD_ERROR':
            userMessage = '파일 업로드 중 오류가 발생했습니다. 잠시 후 다시 시도해주세요.';
            break;
        }

        // 에러 발생 시 다이얼로그 닫기
        closeUploadDialog();

        await UtilAlertF.error({
          title: '업로드 실패',
          msg: userMessage,
        });
      } finally {
        $.don_loader_end();
      }
    };

    if (lastEditedSvg) {
      showUploadDialog({
        lastEditedSvg,
        up_id,
        onConfirm: handleCuttingFileUpload,
        onSelectNew: async () => {
          try {
            await handleFileUpload({
              accept: '.svg',
              onFileSelect: handleCuttingFileUpload,
            });
          } catch (e) {
            await UtilAlertF.error({ title: 'Error', msg: '업로드 중 오류가 발생했습니다.' });
          }
        },
        onCancel: () => {
          closeUploadDialog(); // 취소 시 다이얼로그 닫기
        },
      });
    } else {
      try {
        // 편집된 SVG가 없는 경우 바로 파일 선택
        handleFileUpload({
          accept: '.svg',
          onFileSelect: handleCuttingFileUpload,
        });
      } catch (e) {
        await UtilAlertF.error({ title: 'Error', msg: '업로드 중 오류가 발생했습니다.' });
      }
    }
  });
}
