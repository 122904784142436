import { zplCMD } from '../../../Stock/zpl_module/zpl_command_utils.js';
import { DfInhouseF } from '../../../Inhouse/F/Function/module/DfInhouseF.js';
import { isNil, find } from 'fxjs/es';
import { DfWaybillF } from './module/DfWaybillF.js';
import { UtilArrayS } from '../../../../Util/Array/S/Function/module/UtilArrayS.js';

const img_label_print_connection_error =
  '//s3.marpple.co/files/u_1187078/2021/12/original/f4535d5dce29afd5e3ada6ad2b087941171877161.png';

export const printer_config_fn = {
  // 주문서 라벨
  projection_label: async (device) => {
    await zplCMD.setMediaType(device, 'T'); //Thermal_transfer
    await zplCMD.setPrintMode(device, 'T'); //Tear-off mode
    await zplCMD.setTearLineOffset(device, '000');
    await zplCMD.setPrintWidth(device, 50, 203);
    await zplCMD.setEncodingTable(device, 'E:UHANGUL.DAT', 26);
    await zplCMD.setFontIdentifier(device, 'J', 'E:NANUMGOTHICCODIN.TTF');
  },
  // 품표 라벨
  product_label: async (device) => {
    await zplCMD.setMediaType(device, 'D'); // Direct Thermal
    await zplCMD.setPrintMode(device, 'T'); // Tear-Off
    await zplCMD.setPrintWidth(device, 100, 203); // 헤드 작동 폭, dpi
    await zplCMD.setEncodingTable(device, 'E:UHANGUL.DAT', 26);
    await zplCMD.setPrintSpeed(device, 3);
    await zplCMD.setDarkness(device, 23);
    await zplCMD.setFontIdentifier(device, 'R', 'E:NGC-R.TTF'); // Regular
    await zplCMD.setFontIdentifier(device, 'W', 'E:NGC-B.TTF'); // Weight
  },
  // 재고 라벨
  stock_label: async (device) => {
    await zplCMD.setMediaType(device, 'T'); //Thermal_transfer
    await zplCMD.setPrintMode(device, 'C'); //Cutter mode
    await zplCMD.setTearLineOffset(device, '030');
    await zplCMD.setPrintWidth(device, 120, 300);
    await zplCMD.setEncodingTable(device, 'E:UHANGUL.DAT', 26);
    await zplCMD.setFontIdentifier(device, 'J', 'E:NANUMGOTHICCODIN.TTF');
  },
  // 사내배송 버킷
  inhouse_projection_box: async (device) => {
    await zplCMD.setMediaType(device, 'D'); //Direct Thermal (감열)
    await zplCMD.setPrintMode(device, 'T'); //Tear-Off (뜯는 방식)
    await zplCMD.setPrintWidth(device, 102, 203); //가로폭 100mm 운송장
    await zplCMD.setEncodingTable(device, 'E:UHANGUL.DAT', 26); //한글 인코딩 테이블
    await zplCMD.setFontIdentifier(device, 'J', 'E:NANUMGOTHICCODIN.TTF'); //한글폰트 - G=HY견고딕TTF
  },
  // CJ 운송장
  waybill_cj: async (device) => {
    await zplCMD.setMediaType(device, 'D'); //Direct Thermal (감열)
    await zplCMD.setPrintMode(device, 'T'); //Tear-Off (뜯는 방식)
    await zplCMD.setPrintWidth(device, 102, 203); //가로폭 100mm 운송장
    await zplCMD.setEncodingTable(device, 'E:UHANGUL.DAT', 26); //한글 인코딩 테이블
    await zplCMD.setFontIdentifier(device, 'J', 'E:NANUMGOTHICCODIN.TTF');
    await zplCMD.setFontIdentifier(device, 'G', 'E:HYGTRE.TTF'); //한글폰트 - G=HY견고딕TTF
  },
};

// @description 라벨 프린터를 점검하고 browser printer SDK 로부터 연결된 프린터 객체 device 를 가져오는 함수
export const prepareLabelPrinter = async ({ setting_fn, allowed_devices, printer_serial }) => {
  const device = await DfWaybillF.getPrinterDevice({ allowed_devices, printer_serial });
  await DfWaybillF.communicateTestWithDevice({ device });
  setting_fn && (await DfWaybillF.configLabelPrinterDevice({ device, settingFn: setting_fn }));
  return device;
};

// @description 허용된 디바이스 값이 있으면 디바이스 모델명으로 찾기, printer_serial 이 있는 경우 특정 serial 번호를 가진 기기 검색
export async function getPrinterDevice({ allowed_devices, printer_serial }) {
  const device = printer_serial
    ? await DfWaybillF.getLabelPrinterDeviceFromSerialNo({ printer_serial })
    : await DfWaybillF.getLabelPrinterDeviceFromDefault();

  return device;
}

// @description 시리얼 번호와 맞는 라벨 프린터 기기 리턴
export async function getLabelPrinterDeviceFromSerialNo({ printer_serial }) {
  let devices;

  try {
    devices = await zplCMD.getLocalLabelDevices(3000);
  } catch (err) {
    throwLabelPrinterError({
      msg: `Zebra browser printer 가 필요합니다.<br><br>설치 되어 있으면 프로그램을 실행을 시켜주세요.<br>설치되어 있지 않으면 <a style="color:#1b77ff;text-decoration: underline;" href="https://www.zebra.com/us/en/support-downloads/printer-software/by-request-software.html#browser-print" target="_blank">설치 링크</a> 에서 설치해 주세요.`,
    });
  }

  if (UtilArrayS.isEmNil(devices)) {
    throwLabelPrinterError({
      msg: `등록된 라벨 프린터를 찾을 수 없습니다.<br>Browser Printer 에서 프린터 등록을 진행해 주세요.`,
    });
  }

  const target_device = find(
    (device) => device.uid && device.uid.toLowerCase().includes(printer_serial.toLowerCase()),
    devices,
  );
  if (UtilArrayS.isEmNil(target_device)) {
    throwLabelPrinterError({
      msg: `${printer_serial} 시리얼 번호<br>라벨 프린터를 찾지 못했습니다.<br>Browser Printer 에서 등록된 프린터를 확인해 주세요.`,
    });
  }

  return target_device;
}

// @description 브라우저 프린터의 기본값 프린터로 등록되어 있는 라벨 프린터 기기 리턴
export async function getLabelPrinterDeviceFromDefault() {
  let device;
  try {
    device = await zplCMD.getDefaultLabelDevice(1000);
  } catch (err) {
    throwLabelPrinterError({
      msg: `Zebra browser printer 가 필요합니다.<br><br>설치 되어 있으면 프로그램을 실행을 시켜주세요.<br>설치되어 있지 않으면 <a style="color:#1b77ff;text-decoration: underline;" href="https://www.zebra.com/us/en/support-downloads/printer-software/by-request-software.html#browser-print" target="_blank">설치 링크</a> 에서 설치해 주세요.`,
    });
  }
  if (device == null) {
    throwLabelPrinterError({
      msg: `등록된 라벨 프린터를 찾을 수 없네요.<br>Browser Printer 에서 프린터 등록을 진행해 주세요.`,
    });
  }

  return device;
}

// @description 라벨 프린터 통신 응답 테스트
export async function communicateTestWithDevice({ device }) {
  let host_status;
  try {
    host_status = await zplCMD.getDeviceStatus(device);
  } catch (error) {
    throwLabelPrinterError({
      msg: `라벨 프린터 연결이 끊어진 것 같아요.<br>프린터와 연결을 점검해 주세요.`,
    });
  }

  if (isNil(host_status.communication_settings.baud_rate)) {
    throwLabelPrinterError({
      msg: `해당 라벨 프린터와 통신 문제가 있는 것 같아요.<br>프린터 연결을 점검해 주세요.`,
    });
  }
}

export async function configLabelPrinterDevice({ device, settingFn }) {
  // 라벨 프린터 세팅
  try {
    await settingFn(device);
  } catch (error) {
    throwLabelPrinterError({
      msg: `라벨 프린터에 설정값 쓰기를 할 수 없어요.<br>연결을 점검해 주세요.`,
    });
  }
}

function throwLabelPrinterError({ msg }) {
  DfInhouseF.lottie_loader.end();
  const _error = new Error(msg);
  _error.image = img_label_print_connection_error;
  throw _error;
}
