import { html } from 'fxjs/es';

export function svgReplacementButton() {
  return html`
    <div class="replace-cutting-files">
      <button type="button" style="font-size:11px; background-color: #e8f0fe; margin-bottom: 6px;">
        칼리뷰에디터
      </button>
    </div>
  `;
}
