import { UtilS } from '../../../../../Util/S/Function/module/UtilS.js';
import { convertToLengthUnitToPixels } from './common.js';
import { createImageElFromBlob } from './image.js';

export function injectDpiToSvgElement(svgElement, dpi = 72) {
  const svgClone = svgElement.cloneNode(true);

  // SVG에 DPI 정보 설정
  const pixelsPerInch = dpi;
  const pixelsPerCm = pixelsPerInch / 2.54;

  svgClone.setAttribute('width', `${svgClone.width.baseVal.value / pixelsPerCm}cm`);
  svgClone.setAttribute('height', `${svgClone.height.baseVal.value / pixelsPerCm}cm`);

  // viewBox가 없는 경우 추가
  if (
    !svgClone.hasAttribute('viewBox') &&
    svgClone.hasAttribute('width') &&
    svgClone.hasAttribute('height')
  ) {
    svgClone.setAttribute('viewBox', `0 0 ${svgClone.width.baseVal.value} ${svgClone.height.baseVal.value}`);
  }

  return svgClone;
}

/**
 * 이미지와 SVG를 결합합니다.
 */
export async function getSvgCombinedWithImage({ image_url, svg_url }) {
  try {
    // SVG 템플릿 다운로드
    const svgResponse = await fetch(UtilS.ensureProtocol(svg_url));
    const svgText = await svgResponse.text();

    // 이미지 다운로드
    const imgResponse = await fetch(UtilS.ensureProtocol(image_url));
    const imgBlob = await imgResponse.blob();

    // 이미지를 Canvas에 그리기
    const img = await createImageElFromBlob(imgBlob);
    const canvas = document.createElement('canvas');
    canvas.width = img.width;
    canvas.height = img.height;
    const ctx = canvas.getContext('2d');
    if (!ctx) throw new Error('Cannot get canvas context');
    ctx.drawImage(img, 0, 0);

    // SVG 파싱
    const parser = new DOMParser();
    const svgDoc = parser.parseFromString(svgText, 'image/svg+xml');
    const svgElement = svgDoc.documentElement;

    // viewBox 파싱 (단위 고려)
    const viewBox = svgElement.getAttribute('viewBox') || '0 0 600 600';
    const [vbX, vbY, vbWidth, vbHeight] = parseViewBox(viewBox);

    if (vbX == null || vbY == null || vbWidth == null || vbHeight == null) throw new Error('Invalid viewBox');

    // viewBox가 0,0이 아닌 경우 모든 path 요소를 이동
    if (vbX !== 0 || vbY !== 0) {
      const paths = svgElement.getElementsByTagName('path');
      Array.from(paths).forEach((path) => {
        const d = path.getAttribute('d');
        if (d) {
          const newD = translatePathData(d, -vbX, -vbY);
          path.setAttribute('d', newD);
        }
      });

      // viewBox를 0,0 기준으로 재설정
      svgElement.setAttribute('viewBox', `0 0 ${vbWidth} ${vbHeight}`);
    }

    // 이미지 스케일 계산 (비율 유지하면서 SVG에 맞추기)
    const scale = Math.min(vbWidth / img.width, vbHeight / img.height);
    const scaledWidth = img.width * scale;
    const scaledHeight = img.height * scale;

    // 중앙 정렬을 위한 위치 계산
    const x = (vbWidth - scaledWidth) / 2;
    const y = (vbHeight - scaledHeight) / 2;

    // 새로운 SVG 생성
    const newSvg = document.createElementNS('http://www.w3.org/2000/svg', 'svg');

    // 원본 SVG의 속성들을 복사
    Array.from(svgElement.attributes).forEach((attr) => {
      newSvg.setAttribute(attr.name, attr.value);
    });

    newSvg.setAttribute('data-dpi', '300');

    // 이미지 엘리먼트 생성 및 설정
    const imageElement = document.createElementNS('http://www.w3.org/2000/svg', 'image');
    imageElement.setAttributeNS('http://www.w3.org/1999/xlink', 'xlink:href', canvas.toDataURL('image/png'));
    imageElement.setAttribute('width', scaledWidth.toString());
    imageElement.setAttribute('height', scaledHeight.toString());
    imageElement.setAttribute('x', x.toString());
    imageElement.setAttribute('y', y.toString());
    imageElement.setAttribute('preserveAspectRatio', 'none');

    // 이미지를 SVG의 첫 번째 요소로 추가
    newSvg.appendChild(imageElement);

    // 원본 SVG의 모든 자식 노드들을 복사하여 추가
    Array.from(svgElement.children).forEach((child) => {
      newSvg.appendChild(child.cloneNode(true));
    });

    // SVG를 문자열로 변환
    const serializer = new XMLSerializer();
    return {
      svgString: serializer.serializeToString(newSvg),
      viewBox: {
        x: vbX,
        y: vbY,
        width: vbWidth,
        height: vbHeight,
      },
    };
  } catch (error) {
    console.error('Error combining SVG with image:', error);
    throw error;
  }
}

/**
 * viewBox 문자열을 파싱하여 픽셀 단위로 변환합니다.
 * @param {string} viewBox - viewBox 문자열
 * @returns {(number | null)[]} [x, y, width, height] 픽셀 단위로 변환된 값
 */
function parseViewBox(viewBox) {
  // 공백으로 분리하되, 단위가 있는 경우를 고려
  const parts = viewBox.trim().split(/\s+(?=[\d.-])/);
  return parts.map((part) => convertToLengthUnitToPixels(part));
}

/**
 * SVG path 데이터를 파싱하고 이동시킵니다.
 * @param {string} d - path의 d 속성 값
 * @param {number} dx - x축 이동량
 * @param {number} dy - y축 이동량
 * @returns {string} 변환된 path 데이터
 */
function translatePathData(d, dx, dy) {
  // 숫자와 명령어를 분리하여 배열로 변환
  const parts = d.match(/[a-zA-Z]|[-+]?[0-9]*\.?[0-9]+/g);
  if (!parts) return d;

  let result = '';
  let index = 0;

  while (index < parts.length) {
    const command = parts[index];

    // 명령어가 아닌 경우 그대로 추가
    if (!/[a-zA-Z]/.test(command)) {
      result += command + ' ';
      index++;
      continue;
    }

    // 대문자 명령어인지 확인 (절대 좌표)
    const isAbsolute = command === command.toUpperCase();
    const cmd = command.toUpperCase();

    // 명령어 추가
    result += command;
    index++;

    // 각 명령어별 파라미터 개수
    const paramCount =
      {
        M: 2,
        L: 2,
        H: 1,
        V: 1,
        C: 6,
        S: 4,
        Q: 4,
        T: 2,
        A: 7,
      }[cmd] || 0;

    // 현재 명령어의 파라미터들을 처리
    if (paramCount > 0) {
      for (let i = 0; i < paramCount && index < parts.length; i++) {
        const val = parseFloat(parts[index]);

        // x 좌표 이동 (홀수 번째 파라미터)
        if (isAbsolute && i % 2 === 0 && 'MLCSQT'.includes(cmd)) {
          result += val + dx;
        }
        // y 좌표 이동 (짝수 번째 파라미터)
        else if (isAbsolute && i % 2 === 1 && 'MLCSQT'.includes(cmd)) {
          result += val + dy;
        }
        // H 명령어 (가로 이동)
        else if (isAbsolute && cmd === 'H') {
          result += val + dx;
        }
        // V 명령어 (세로 이동)
        else if (isAbsolute && cmd === 'V') {
          result += val + dy;
        }
        // 상대 좌표이거나 다른 파라미터는 그대로 사용
        else {
          result += val;
        }

        result += ' ';
        index++;
      }
    }
  }

  return result.trim();
}

/**
 * SVG의 viewport를 조정하여 padding을 추가하거나 제거합니다.
 * @param {string} svgString - SVG 문자열
 * @param {number} padding - 적용할 padding 값 (양수: 추가, 음수: 제거)
 */
function adjustSvgPadding(svgString, padding) {
  const parser = new DOMParser();
  const doc = parser.parseFromString(svgString, 'image/svg+xml');
  const svg = doc.documentElement;

  // 현재 viewBox 값 가져오기
  const viewBox = svg.getAttribute('viewBox')?.split(' ').map(Number) || [0, 0, 0, 0];

  // padding 적용한 새로운 viewBox 설정
  const newViewBox = [
    viewBox[0] - padding, // x
    viewBox[1] - padding, // y
    viewBox[2] + padding * 2, // width
    viewBox[3] + padding * 2, // height
  ].join(' ');

  svg.setAttribute('viewBox', newViewBox);

  // width와 height 속성 조정
  const width = svg.getAttribute('width');
  const height = svg.getAttribute('height');

  if (width || height) {
    const unitRegex = /([0-9.]+)([a-z%]*)/i;

    const processAttribute = (value) => {
      if (!value) return null;

      const match = value.match(unitRegex);
      if (!match) return null;

      const [, numStr, unit] = match;
      const num = parseFloat(numStr);

      // 단위별 처리 (300 DPI 기준)
      switch (unit.toLowerCase()) {
        case 'px':
          return `${num + padding * 2}px`;
        case 'in':
          return `${num + (padding * 2) / 300}in`;
        case 'mm':
          return `${num + ((padding * 2) / 300) * 25.4}mm`;
        case 'cm':
          return `${num + ((padding * 2) / 300) * 2.54}cm`;
        case 'pt':
          return `${num + ((padding * 2) / 300) * 72}pt`;
        case 'pc':
          return `${num + ((padding * 2) / 300) * 6}pc`;
        case '%': {
          const paddingPercent = ((padding * 2) / viewBox[2]) * 100;
          return `${num + paddingPercent}%`;
        }
        default:
          return `${num + padding * 2}px`;
      }
    };

    const newWidth = processAttribute(width);
    const newHeight = processAttribute(height);

    if (newWidth) svg.setAttribute('width', newWidth);
    if (newHeight) svg.setAttribute('height', newHeight);
  }

  return new XMLSerializer().serializeToString(doc);
}

/**
 * SVG에 padding을 추가하기 위해 viewport를 조정합니다.
 */
export function addPaddingToSvg({ svgString, padding }) {
  return adjustSvgPadding(svgString, padding);
}

/**
 * SVG에서 padding을 제거하기 위해 viewport를 조정합니다.
 */
export function removePaddingFromSvg(svgString, padding) {
  return adjustSvgPadding(svgString, -padding);
}

/**
 * SVG 요소의 크기를 조정합니다.
 */
export function adjustSvgSize(svgElement, targetWidth = 600) {
  const viewBox = svgElement.getAttribute('viewBox')?.split(' ').map(Number) || [0, 0, 0, 0];
  const [, , width, height] = viewBox;
  const aspectRatio = width / height;

  if (aspectRatio >= 1) {
    // 가로가 더 긴 경우
    svgElement.style.width = `${targetWidth}px`;
    svgElement.style.height = `${targetWidth / aspectRatio}px`;
  } else {
    // 세로가 더 긴 경우
    svgElement.style.height = `${targetWidth}px`;
    svgElement.style.width = `${targetWidth * aspectRatio}px`;
  }
}
