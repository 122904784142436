/**
 * Blob으로부터 Image 객체를 생성합니다.
 */
export async function createImageElFromBlob(blob) {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = () => resolve(img);
    img.onerror = reject;
    img.src = URL.createObjectURL(blob);
  });
}

/**
 * PNG 파일에 DPI 정보를 주입합니다.
 */
export async function injectDpiToPngBlob(blob, dpi = 300) {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.onload = () => {
      try {
        const arrayBuffer = fileReader.result;
        if (!arrayBuffer || typeof arrayBuffer === 'string') {
          throw new Error('Invalid file data');
        }

        const view = new DataView(arrayBuffer);
        const newArrayBuffer = new ArrayBuffer(arrayBuffer.byteLength + 21); // pHYs chunk size
        const newView = new DataView(newArrayBuffer);

        // PNG 시그니처와 IHDR 청크 복사 (처음 33바이트)
        for (let i = 0; i < 33; i++) {
          newView.setUint8(i, view.getUint8(i));
        }

        // pHYs 청크 추가
        const pixelsPerMeter = Math.round(dpi * 39.3701); // DPI to pixels per meter

        newView.setUint32(33, 9); // pHYs chunk length
        newView.setUint32(37, 0x70485973); // "pHYs"
        newView.setUint32(41, pixelsPerMeter); // pixels per meter X
        newView.setUint32(45, pixelsPerMeter); // pixels per meter Y
        newView.setUint8(49, 1); // unit is meters

        // CRC32 계산
        const crcData = new Uint8Array(newArrayBuffer.slice(37, 50));
        const crc = calculateCRC32(crcData);
        newView.setUint32(50, crc);

        // 나머지 데이터 복사
        for (let i = 33; i < arrayBuffer.byteLength; i++) {
          newView.setUint8(i + 21, view.getUint8(i));
        }

        const newBlob = new Blob([newArrayBuffer], { type: 'image/png' });
        resolve(newBlob);
      } catch (error) {
        reject(error);
      }
    };
    fileReader.onerror = reject;
    fileReader.readAsArrayBuffer(blob);
  });
}

/**
 * CRC32 체크섬을 계산합니다.
 */
function calculateCRC32(data) {
  let crc = -1;
  const table = new Int32Array(256);

  // CRC32 테이블 생성
  for (let i = 0; i < 256; i++) {
    let c = i;
    for (let j = 0; j < 8; j++) {
      c = c & 1 ? -306674912 ^ (c >>> 1) : c >>> 1;
    }
    table[i] = c;
  }

  // CRC32 계산
  for (let i = 0; i < data.length; i++) {
    crc = table[(crc ^ data[i]) & 0xff] ^ (crc >>> 8);
  }

  return ~crc;
}

/**
 * SVG 문자열을 Blob URL로 변환합니다.
 * @param {string} svgString - SVG 문자열
 * @returns {string} Blob URL
 */
export function svgToUrl(svgString) {
  const blob = new Blob([svgString], { type: 'image/svg+xml' });
  return URL.createObjectURL(blob);
}

/**
 * SVG를 PNG로 변환합니다.
 * @param {Object} options - 변환 옵션
 * @param {string} options.svgString - SVG 문자열
 * @param {number} options.width - 이미지 너비
 * @param {number} options.height - 이미지 높이
 * @param {number} [options.scale=1] - 스케일 (기본값: 1)
 * @param {number} [options.dpi=300] - DPI (기본값: 300)
 * @returns {Promise<Blob>} PNG Blob
 */
export async function svgToPng(options) {
  const { svgString, width, height, scale = 1, dpi = 300 } = options;
  try {
    // SVG를 URL로 변환
    const svgUrl = svgToUrl(svgString);

    // SVG 이미지 로드
    const img = await new Promise((resolve, reject) => {
      const image = new Image();
      image.onload = () => resolve(image);
      image.onerror = reject;
      image.src = svgUrl;
    });

    // Canvas 생성 및 설정
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');

    if (!ctx) {
      throw new Error('Canvas 2D context를 생성할 수 없습니다.');
    }

    // 스케일을 적용한 캔버스 크기 설정
    canvas.width = width * scale;
    canvas.height = height * scale;

    // 배경을 투명하게 설정
    ctx.clearRect(0, 0, canvas.width, canvas.height);

    // 이미지 그리기
    ctx.drawImage(img, 0, 0, canvas.width, canvas.height);

    // Canvas를 PNG Blob으로 변환
    const blob = await new Promise((resolve) => {
      canvas.toBlob(resolve, 'image/png');
    });

    if (!blob) {
      throw new Error('PNG Blob을 생성할 수 없습니다.');
    }

    // URL 정리
    URL.revokeObjectURL(svgUrl);

    // DPI 정보 주입
    return await injectDpiToPngBlob(blob, dpi);
  } catch (error) {
    console.error('SVG를 PNG로 변환하는 중 오류가 발생했습니다:', error);
    throw error;
  }
}

/**
 * SVG를 PNG로 변환하여 다운로드합니다.
 * @param {Object} options - 변환 옵션
 * @param {string} options.svgString - SVG 문자열
 * @param {number} options.width - 이미지 너비
 * @param {number} options.height - 이미지 높이
 * @param {number} [options.scale=1] - 스케일 (기본값: 1)
 * @param {number} [options.dpi=300] - DPI (기본값: 300)
 * @param {string} filename - 다운로드할 파일 이름
 */
export async function downloadSvgAsPng(options, filename) {
  try {
    const pngBlob = await svgToPng(options);
    const url = URL.createObjectURL(pngBlob);

    const link = document.createElement('a');
    link.href = url;
    link.download = filename;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    // URL 정리
    setTimeout(() => URL.revokeObjectURL(url), 100);
  } catch (error) {
    console.error('SVG 다운로드 중 오류가 발생했습니다:', error);
    throw error;
  }
}
