import paper from 'paper';
import { createEditorDialog } from './createEditorDialog.js';
import { getUserFriendlyErrorMessage, showErrorUI } from './errorHandling.js';
import { setupEventHandlers } from './eventHandlers.js';
import { setupTooltip } from './tooltipSetup.js';
import { initVectorEditor } from './initEditor.js';

/**
 * 칼선 편집기를 엽니다.
 * @param {string} cutting_line_svg_url - 칼선 SVG URL
 * @param {string} printable_png_url - 인쇄 가능한 PNG URL
 * @param {HTMLElement} vectorPopupDom - 벡터 팝업 DOM 요소
 * @parma {function} onClose - 닫힐 때 호출될 콜백 함수
 */
export async function openModal(cutting_line_svg_url, printable_png_url, vectorPopupDom, onClose) {
  return new Promise((resolve) => {
    // 다이얼로그 생성
    const dialog = createEditorDialog();
    document.body.appendChild(dialog);
    dialog.showModal();

    // 도움말 툴팁 설정
    const helpIcon = dialog.querySelector('#help-icon');
    if (helpIcon && helpIcon instanceof HTMLElement) {
      setupTooltip(helpIcon);
    }

    const canvas = dialog.querySelector('#cut-editor');
    if (!canvas || !(canvas instanceof HTMLCanvasElement)) return;

    // Paper.js 초기화
    paper.setup(canvas);
    paper.settings.handleSize = 8;

    let zoomPanControl = null;

    const cleanup = () => {
      if (zoomPanControl) {
        zoomPanControl.cleanup();
      }
      paper.project.clear();
      dialog.remove();

      // vector 팝업으로 포커스 이동
      vectorPopupDom.focus();
    };

    // SVG와 PNG 로드 및 편집 기능 초기화
    (async () => {
      try {
        $.don_loader_start();
        const result = await initVectorEditor({
          cutting_line_svg_url,
          printable_png_url,
          canvas,
          dialog,
          resolve,
        });

        if (!result) {
          throw new Error('편집기 초기화에 실패했습니다.');
        }

        const { zoomPanControl: newZoomPanControl, toolEvents } = result;

        if (!newZoomPanControl || !toolEvents) {
          throw new Error('편집기 컨트롤 초기화에 실패했습니다.');
        }

        zoomPanControl = newZoomPanControl;

        // 이벤트 핸들러 설정
        setupEventHandlers({
          dialog,
          cleanup,
          resolve,
          toolEvents,
          onClose,
        });
      } catch (error) {
        console.error('Error initializing editor:', error);

        const dialogContent = dialog.querySelector('div');
        if (dialogContent) {
          const originalContent = dialogContent.innerHTML;
          const errorMessage = getUserFriendlyErrorMessage(error);

          showErrorUI({
            dialogContent,
            errorMessage,
            cleanup,
            resolve,
            error,
            originalContent,
            retryInitialization: async () => {
              const result = await initVectorEditor({
                cutting_line_svg_url,
                printable_png_url,
                canvas,
                dialog,
                resolve,
              });

              if (!result) {
                throw new Error('편집기 초기화에 실패했습니다.');
              }

              const { zoomPanControl: newZoomPanControl, toolEvents } = result;

              if (!newZoomPanControl || !toolEvents) {
                throw new Error('편집기 컨트롤 초기화에 실패했습니다.');
              }

              zoomPanControl = newZoomPanControl;

              // 이벤트 핸들러 설정
              setupEventHandlers({
                dialog,
                cleanup,
                resolve,
                toolEvents,
                onClose,
              });
            },
          });
        } else {
          cleanup();
          resolve({ isConfirmed: false, error });
        }
      } finally {
        // 초기화 성공 후 다이얼로그 표시
        requestAnimationFrame(() => {
          dialog.style.opacity = '1';
        });
        $.don_loader_end();
      }
    })();
  });
}
