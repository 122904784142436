import paper from 'paper';

export function setupToolEvents({ isPanning = false } = {}) {
  const tool = new paper.Tool();
  let selectedSegment = null;
  let selectedPath = null;
  let selectedHandle = null;
  const undoStack = [];
  let isCurrentlyPanning = isPanning; // let으로 변경하여 상태 변경 가능하도록 함

  // 기본 설정
  paper.settings.handleSize = 8;
  paper.settings.strokeWidth = 2;
  paper.settings.handleColor = '#2156FF'; // 핸들 색상 설정

  const BASE_TOLERANCE = 100;
  const BASE_MIN_DISTANCE = 0.1;

  // hitOptions를 클로저 내부에서 관리
  const hitOptions = {
    segments: true,
    stroke: true,
    handles: true,
    fill: true,
    tolerance: BASE_TOLERANCE,
    match: (result) => {
      // segment, handle, stroke, fill만 매칭
      return ['segment', 'handle-in', 'handle-out', 'stroke', 'fill'].includes(result.type);
    },
  };

  // 상태 저장
  function saveState() {
    const state = paper.project.exportJSON();
    undoStack.push(state);
    // 스택 크기 제한
    if (undoStack.length > 50) undoStack.shift();
  }

  // 상태 복원
  function restoreState() {
    if (undoStack.length > 0) {
      const state = undoStack.pop();
      paper.project.clear();
      paper.project.importJSON(state);
      if (selectedPath) {
        updatePathSelection(selectedPath);
      }
      paper.view.update();
    }
  }

  // 선택 해제
  function deselectAll() {
    // 선택된 모든 아이템 찾기
    const selectedItems = paper.project.getItems({
      selected: true,
    });

    // 찾은 아이템들의 선택 상태 해제
    selectedItems.forEach((item) => {
      if (item instanceof paper.Path) {
        item.selected = false;
        item.fullySelected = false;

        // 모든 세그먼트의 선택 상태 해제
        item.segments.forEach((segment) => {
          segment.selected = false;
          if (segment.handleIn) segment.handleIn.selected = false;
          if (segment.handleOut) segment.handleOut.selected = false;
        });
      }
    });

    // 상태 변수 초기화
    selectedSegment = null;
    selectedPath = null;
    selectedHandle = null;

    // 변경사항 즉시 반영
    paper.view.update();
  }

  // 경로 선택 상태 업데이트
  function updatePathSelection(path) {
    if (!path || !(path instanceof paper.Path)) return;

    // 다른 모든 path의 선택 상태 해제
    paper.project.activeLayer.children.forEach((p) => {
      if (p instanceof paper.Path && p !== path) {
        p.selected = false;
        p.fullySelected = false;
        p.segments.forEach((s) => (s.selected = false));
      }
    });

    // 선택된 path만 활성화
    path.fullySelected = true;
    path.segments.forEach((segment) => {
      segment.selected = segment === selectedSegment;
    });

    // 변경사항 즉시 반영
    paper.view.update();
  }

  // 줌 레벨에 따른 도구 민감도 조정
  function updateToolSensitivity() {
    const zoom = paper.view.zoom;

    // 줌 레벨이 높을수록 더 세밀하게 반응하도록 조정
    tool.minDistance = Math.max(BASE_MIN_DISTANCE / zoom, 0.01);

    // tolerance를 로그 스케일로 조정하고 zoom level에 따라 더 급격하게 감소
    let tolerance;
    const logZoom = Math.log10(zoom);
    const scaledBaseTolerance = BASE_TOLERANCE / 100; // 기본 허용 오차를 스케일링

    if (zoom >= 200) {
      tolerance = scaledBaseTolerance / (Math.pow(logZoom, 3) * 2);
    } else if (zoom >= 100) {
      tolerance = scaledBaseTolerance / (Math.pow(logZoom, 2.5) * 2);
    } else if (zoom >= 50) {
      tolerance = scaledBaseTolerance / (Math.pow(logZoom, 2) * 2);
    } else if (zoom >= 10) {
      tolerance = scaledBaseTolerance / (Math.pow(logZoom, 1.5) * 2);
    } else {
      tolerance = scaledBaseTolerance / (Math.pow(logZoom, 1) * 2);
    }

    // tolerance의 최소값을 0.0005로 낮춤
    hitOptions.tolerance = Math.max(0.0005, tolerance);
  }

  // 마우스 다운 이벤트
  tool.onMouseDown = function (event) {
    if (isCurrentlyPanning) return;

    updateToolSensitivity();
    const hitResult = paper.project.hitTest(event.point, hitOptions);

    // hitResult가 없거나 path와 관련없는 부분을 클릭한 경우 모든 선택 해제
    if (!hitResult || !['segment', 'handle-in', 'handle-out', 'stroke', 'fill'].includes(hitResult.type)) {
      deselectAll();
    }

    // Option/Alt 키가 눌린 상태에서 stroke를 클릭 하면 새로운 segment 추가
    if (event.modifiers.option && hitResult && hitResult.type === 'stroke') {
      const path = hitResult.item;
      if (!(path instanceof paper.Path)) return;

      const location = hitResult.location;
      const tangent = location.tangent;
      const curve = location.curve;
      const t = location.offset / curve.length;

      // 새로운 segment 추가
      saveState();
      const newSegment = path.insert(location.index + 1, event.point);

      // 기존 곡선의 tangent와 curvature를 기반으로 handle 설정
      // 곡선 길이의 5%를 기본값으로 하되, 최대 3px를 넘지 않도록 제한
      const baseLength = curve.length * 0.05;
      const handleLength = Math.min(baseLength, 3);

      // 이전 segment의 handleOut 조정 (점에서 나가는 방향)
      const prevSegment = path.segments[location.index];
      if (prevSegment) {
        const prevHandleLength = handleLength * (1 - t);
        // tangent는 이미 정규화된 벡터이므로, 원하는 길이로 정확하게 설정
        prevSegment.handleOut = tangent.normalize().multiply(prevHandleLength);
      }

      // 새로운 segment의 handle 설정
      // handleIn은 이전 커브에서 들어오는 방향 (현재 tangent의 반대)
      // handleOut은 다음 커브로 나가는 방향 (다음 커브의 시작 방향)
      const nextTangent = curve.getTangentAt(t);
      newSegment.handleIn = tangent.normalize().multiply(handleLength * (1 - t));
      newSegment.handleOut = nextTangent.normalize().multiply(handleLength * t);

      // 다음 segment의 handleIn 조정
      const nextSegment = path.segments[location.index + 2];
      if (nextSegment) {
        const nextHandleLength = handleLength * t;
        nextSegment.handleIn = nextTangent.normalize().multiply(-nextHandleLength);
      }

      // 새로 추가된 segment 선택
      deselectAll();
      selectedSegment = newSegment;
      selectedPath = path;
      updatePathSelection(path);
      return;
    }

    // hit된 결과 처리
    if (hitResult) {
      deselectAll();
      if (hitResult.type === 'segment') {
        selectedSegment = hitResult.segment;
        selectedPath = hitResult.item;
        selectedHandle = null; // 새로운 segment 선택 시 handle 선택 초기화
        saveState();
      } else if (hitResult.type === 'handle-in') {
        selectedSegment = hitResult.segment;
        selectedPath = hitResult.item;
        selectedHandle = 'handleIn';
        saveState();
      } else if (hitResult.type === 'handle-out') {
        selectedSegment = hitResult.segment;
        selectedPath = hitResult.item;
        selectedHandle = 'handleOut';
        saveState();
      } else if (hitResult.type === 'stroke' || hitResult.type === 'fill') {
        selectedSegment = null; // stroke/fill 선택 시 segment와 handle 선택 초기화
        selectedHandle = null;
        selectedPath = hitResult.item;
      }

      if (selectedPath) {
        updatePathSelection(selectedPath);
      }
    }
  };

  // 마우스 업 이벤트
  tool.onMouseUp = function (event) {};

  // 마우스 드래그 이벤트
  tool.onMouseDrag = function (event) {
    if (isCurrentlyPanning) return;

    if (selectedSegment) {
      if (selectedHandle) {
        // 핸들 드래그
        const handle = selectedSegment[selectedHandle];
        handle.x += event.delta.x;
        handle.y += event.delta.y;
      } else {
        // 점 드래그
        selectedSegment.point = selectedSegment.point.add(event.delta);
      }
      paper.view.update();
    }
  };

  // 키보드 이벤트 핸들러
  function handleKeyDown(event) {
    if (event.key === 'Delete' || event.key === 'Backspace') {
      if (selectedHandle && selectedSegment) {
        // handle이 선택된 경우 handle만 제거
        saveState();
        if (selectedHandle === 'handleIn') {
          selectedSegment.handleIn = new paper.Point(0, 0);
        } else if (selectedHandle === 'handleOut') {
          selectedSegment.handleOut = new paper.Point(0, 0);
        }
        selectedHandle = null;
        paper.view.update();
      } else if (selectedSegment && selectedPath && selectedPath.segments.length > 2) {
        // segment가 선택된 경우 segment 제거
        saveState();
        selectedSegment.remove();
        selectedSegment = null;
        paper.view.update();
      }
      event.preventDefault();
    } else if (event.key === 'z' && (event.metaKey || event.ctrlKey)) {
      restoreState();
      event.preventDefault();
    }
  }

  // 키보드 이벤트 리스너 등록
  document.addEventListener('keydown', handleKeyDown);

  // 도구 정리 함수
  function cleanup() {
    document.removeEventListener('keydown', handleKeyDown);
  }

  // 초기 민감도 설정
  updateToolSensitivity();

  // 패닝 상태 업데이트 함수
  function updatePanningState(newState) {
    isCurrentlyPanning = newState;
  }

  return {
    tool,
    getSelectedSegment: () => selectedSegment,
    getSelectedPath: () => selectedPath,
    cleanup,
    updateToolSensitivity,
    updatePanningState, // 패닝 상태 업데이트 함수 노출
    getUndoStackLength: () => undoStack.length, // undoStack 길이 반환 함수 추가
  };
}

/**
 * 줌/패닝 이벤트를 설정합니다.
 */
export function setupZoomPanEvents(canvas, container) {
  let currentZoom = paper.view.zoom;
  const minZoom = 0.1;
  const maxZoom = 1000;
  let isPanning = false;
  let lastPoint = null;
  let toolEvents = null;

  // 툴 이벤트 설정 함수
  function setToolEvents(events) {
    toolEvents = events;
    // 초기 설정 시 한 번 호출하여 초기 줌 레벨에 맞게 설정
    if (toolEvents && toolEvents.updateToolSensitivity) {
      toolEvents.updateToolSensitivity();
    }
  }

  // 마우스 휠 이벤트로 줌 인/아웃
  const handleWheel = (event) => {
    // Cmd(Mac) 또는 Ctrl(Windows) 키가  려있을 때만 줌 동작
    if (!event.metaKey && !event.ctrlKey) return;

    event.preventDefault();
    event.stopPropagation();
    const delta = event.deltaY * 0.9;

    // 마우스 포인터 위치를 Paper.js 좌표로 변환
    const mousePosition = new paper.Point(event.offsetX, event.offsetY);
    const viewPosition = paper.view.viewToProject(mousePosition);

    // 이전 줌 레벨 저장
    const oldZoom = currentZoom;

    // 새로운 줌 레벨 계산 (더 부드러운 줌을 위해 계수 조정)
    currentZoom = Math.min(maxZoom, Math.max(minZoom, currentZoom * (delta > 0 ? 0.9 : 1.1)));

    // 줌 배율 계산
    const zoomRatio = oldZoom / currentZoom;

    // 뷰포트 중심점과 마우스 포인터 사이의 벡터 계산
    const centerToMouse = viewPosition.subtract(paper.view.center);

    // 새로운 중심점 계산
    const offset = viewPosition.subtract(centerToMouse.multiply(zoomRatio)).subtract(paper.view.center);

    // 뷰 업데이트
    paper.view.zoom = currentZoom;
    paper.view.center = paper.view.center.add(offset);

    // 도구 민감도 업데이트
    if (toolEvents && toolEvents.updateToolSensitivity) {
      toolEvents.updateToolSensitivity();
    }

    paper.view.update();
  };

  // 스페이스바 + 드래그로 패닝
  const handleKeyDown = (event) => {
    if (event.key === ' ' && !isPanning) {
      event.preventDefault();
      event.stopPropagation();
      isPanning = true;
      if (toolEvents && toolEvents.updatePanningState) {
        toolEvents.updatePanningState(true);
      }
      canvas.style.cursor = 'grab';
    }
  };

  const handleKeyUp = (event) => {
    if (event.key === ' ') {
      event.preventDefault();
      event.stopPropagation();
      isPanning = false;
      if (toolEvents && toolEvents.updatePanningState) {
        toolEvents.updatePanningState(false);
      }
      canvas.style.cursor = 'default';
      lastPoint = null;
    }
  };

  const handleMouseDown = (event) => {
    if (isPanning) {
      lastPoint = new paper.Point(event.offsetX, event.offsetY);
      canvas.style.cursor = 'grabbing';
    }
  };

  const handleMouseMove = (event) => {
    if (isPanning && lastPoint) {
      const currentPoint = new paper.Point(event.offsetX, event.offsetY);
      const delta = currentPoint.subtract(lastPoint);

      // 현재 줌 레벨을 고려한 이동 거리 계산
      const scaledDelta = delta.divide(currentZoom);
      paper.view.center = paper.view.center.subtract(scaledDelta);

      lastPoint = currentPoint;
      paper.view.update();
    }
  };

  const handleMouseUp = () => {
    if (isPanning) {
      lastPoint = null;
      canvas.style.cursor = 'grab';
    }
  };

  // 이벤트 리스너 등록
  canvas.addEventListener('wheel', handleWheel, { passive: false });
  container.addEventListener('keydown', handleKeyDown);
  container.addEventListener('keyup', handleKeyUp);
  canvas.addEventListener('mousedown', handleMouseDown);
  canvas.addEventListener('mousemove', handleMouseMove);
  canvas.addEventListener('mouseup', handleMouseUp);

  // 이벤트 리스너 제거 함수 반환
  const cleanup = () => {
    canvas.removeEventListener('wheel', handleWheel);
    container.removeEventListener('keydown', handleKeyDown);
    container.removeEventListener('keyup', handleKeyUp);
    canvas.removeEventListener('mousedown', handleMouseDown);
    canvas.removeEventListener('mousemove', handleMouseMove);
    canvas.removeEventListener('mouseup', handleMouseUp);
  };

  return {
    cleanup,
    isPanning: () => isPanning,
    getCurrentZoom: () => currentZoom,
    setToolEvents, // 툴 이벤트 설정 함수 노출
  };
}
