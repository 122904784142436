import Swal from 'sweetalert2';
import { UtilAlertF } from '../../../../../Util/Alert/F/Function/module/UtilAlertF.js';
import { UtilF } from '../../../../../Util/F/Function/module/UtilF.js';
import { clearLastEditedSvg } from '../cutlineVectorEditor/eventHandlers.js';
import { initializeZoomViews } from '../cutlineVectorEditor/zoomView.js';
import { addPaddingToSvg, adjustSvgSize, getSvgCombinedWithImage } from '../utils/vector.js';
import { setupEventHandlers } from './eventHandlers.js';
import { generatePopupHtml } from './popupTemplate.js';
import { initializeTooltips } from './tooltipSetup.js';

/**
 * 벡터 파일 관리 팝업을 표시합니다.
 */
export async function cutlineReviewPopup({ up_id }) {
  try {
    // 팝업 열릴 때 lastEditedSvg 초기화
    clearLastEditedSvg();

    $.don_loader_start();
    const { svg_product_page_id, printable_png_url, cutting_line_svg_url } = await UtilF.apiCall({
      url: '/@api/svg_product_page',
      method: 'GET',
      params: { up_id },
    });

    // SVG 생성 및 렌더링
    const { svgString, viewBox } = await getSvgCombinedWithImage({
      image_url: printable_png_url,
      svg_url: cutting_line_svg_url,
    });

    const addedPadding = Math.min(viewBox.width, viewBox.height) * 0.1;

    const zoomSize = (addedPadding + (viewBox.width + viewBox.height) / 2) * 0.1;

    // viewport 조정하여 padding 추가
    const combinedSvgString = addPaddingToSvg({
      svgString,
      padding: addedPadding,
    });

    $.don_loader_end();
    await Swal.fire({
      title: '칼리뷰 에디터',
      width: '1440',
      html: generatePopupHtml(combinedSvgString),
      showConfirmButton: false,
      showCloseButton: false,
      allowOutsideClick: false,
      allowEscapeKey: false,
      didOpen: (dom) => {
        // 확대 뷰 초기화
        initializeZoomViews(dom, cutting_line_svg_url, printable_png_url, zoomSize);

        // Tooltip 초기화
        initializeTooltips(dom);

        // SVG 크기 조정
        const svgElement = /** @type {SVGElement} */ (dom.querySelector('#combined-svg-container svg'));
        if (svgElement) {
          adjustSvgSize(svgElement);
        }

        setupEventHandlers(dom, {
          svg_product_page_id,
          up_id,
          addedPadding,
        });
      },
    });
  } catch (err) {
    console.error(err);
    await UtilAlertF.error({ title: 'Error', msg: '알 수 없는 칼선 오류 입니다. 개발팀 문의' });
  } finally {
    $.don_loader_end();
  }
}
