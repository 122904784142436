import paper from 'paper';
import { ensureProtocol } from '../../../../../Util/S/Function/util.js';
import { convertToLengthUnitToPixels } from '../utils/common.js';
import { setupToolEvents, setupZoomPanEvents } from './tools.js';

/**
 * SVG와 PNG를 로드하고 편집 기능을 초기화합니다.
 * @param {Object} params - 초기화에 필요한 파라미터
 * @param {string} params.cutting_line_svg_url - 칼선 SVG URL 또는 SVG 문자열
 * @param {string} params.printable_png_url - 인쇄 가능한 PNG URL
 * @param {HTMLCanvasElement} params.canvas - Paper.js가 초기화된 캔버스
 * @param {HTMLDialogElement} params.dialog - 편집기 다이얼로그
 * @param {Function} params.resolve - Promise resolve 함수
 * @returns {Promise<{ zoomPanControl: any; toolEvents: any; } | null>}
 */
export async function initVectorEditor({ cutting_line_svg_url, printable_png_url, canvas, dialog, resolve }) {
  try {
    let svgString;
    // SVG 문자열인지 URL인지 확인
    if (cutting_line_svg_url.trim().startsWith('<svg')) {
      svgString = cutting_line_svg_url;
    } else {
      // URL인 경우 fetch
      const response = await fetch(ensureProtocol(cutting_line_svg_url));
      svgString = await response.text();
    }

    // SVG width, height를 px로 변환
    const parser = new DOMParser();
    const svgDoc = parser.parseFromString(svgString, 'image/svg+xml');
    const svgElement = svgDoc.documentElement;

    const width = convertToLengthUnitToPixels(svgElement.getAttribute('width'));
    const height = convertToLengthUnitToPixels(svgElement.getAttribute('height'));

    if (width) svgElement.setAttribute('width', width.toString());
    if (height) svgElement.setAttribute('height', height.toString());

    svgString = new XMLSerializer().serializeToString(svgDoc);

    return new Promise((resolveImport) => {
      paper.project.importSVG(svgString, {
        onLoad: (importedSvgItem) => {
          try {
            // Rectangle Shape 제거 및 clipping 비활성화
            const rectangle = importedSvgItem.getItem({ class: paper.Shape.Rectangle });
            if (rectangle) {
              rectangle.remove();
            }
            importedSvgItem.clipped = false;

            // SVG 원본 크기 유지
            const bounds = importedSvgItem.bounds;
            const PADDING_FOR_CONTROL = 50; // 여백

            // view 크기 계산
            const viewScale = Math.min(
              (paper.view.viewSize.width - PADDING_FOR_CONTROL * 2) / bounds.width,
              (paper.view.viewSize.height - PADDING_FOR_CONTROL * 2) / bounds.height,
            );

            // view zoom 설정
            paper.view.zoom = viewScale;
            paper.view.center = bounds.center;

            // 배경 이미지 로드
            const raster = new paper.Raster({
              source: ensureProtocol(printable_png_url),
              position: bounds.center,
              opacity: 1,
              crossOrigin: 'anonymous',
            });

            // 이미지 로드 완료 시 크기 조정
            raster.on({
              load: async () => {
                try {
                  // SVG와 동일한 크기로 조정
                  const rasterScale = bounds.width / raster.width;
                  raster.scale(rasterScale);
                  raster.position = bounds.center;
                  raster.sendToBack();

                  // 줌/패닝 컨트롤 초기화
                  const zoomPanControl = setupZoomPanEvents(canvas, dialog);

                  // 툴 이벤트 초기화
                  const toolEvents = setupToolEvents({ isPanning: zoomPanControl.isPanning() });
                  zoomPanControl.setToolEvents(toolEvents);

                  canvas.setAttribute('tabindex', '0');
                  canvas.focus();

                  resolveImport({ zoomPanControl, toolEvents });
                } catch (error) {
                  console.error('Error processing raster:', error);
                  resolve({ isConfirmed: false, error });
                  resolveImport(null);
                }
              },
              error: (error) => {
                console.error('Error loading raster:', error);
                resolve({ isConfirmed: false, error: new Error('Failed to load background image') });
                resolveImport(null);
              },
            });
          } catch (error) {
            console.error('Error processing SVG:', error);
            resolve({ isConfirmed: false, error });
            resolveImport(null);
          }
        },
        onError: (error) => {
          console.error('Error importing SVG:', error);
          resolve({ isConfirmed: false, error });
          resolveImport(null);
        },
        insert: true,
        expandShapes: false,
      });
    });
  } catch (error) {
    console.error('Error loading SVG or PNG:', error);
    resolve({ isConfirmed: false, error });
    return null;
  }
}
