import tippy from 'tippy.js';
import 'tippy.js/dist/tippy.css';

/**
 * 도움말 아이콘에 툴팁을 설정합니다.
 * @param {HTMLElement} helpIcon - 도움말 아이콘 요소
 */
export function setupTooltip(helpIcon) {
  if (!helpIcon) return;

  const helpContent = `
    <div style="line-height: 1.6;">
      <h4 style="margin: 0 0 8px">칼선 편집 도구 사용법</h4>
      <ul style="margin: 0; padding-left: 16px;">
        <li><strong>선택:</strong> 패스, 점, 핸들 클릭</li>
        <li><strong>점/핸들 이동:</strong> 점 또는 핸들을 드래그</li>
        <li><strong>화면 이동 (패닝):</strong> Space + 드래그</li>
        <li><strong>확대/축소 (줌):</strong> Cmd/Ctrl + 마우스 휠</li>
        <li><strong>새로운 점 추가:</strong> Option/Alt + 선 클릭</li>
        <li><strong>삭제:</strong> 점 선택 후 Delete/Backspace</li>
        <li><strong>임시 반영:</strong> s 키 누르기</li>
        <li><strong>취소:</strong> ESC 키 누르기</li>
      </ul>
    </div>
  `;

  tippy(helpIcon, {
    content: helpContent,
    allowHTML: true,
    placement: 'bottom',
    theme: 'light',
    animation: 'shift-away',
    arrow: true,
    delay: [100, 0],
    zIndex: 999999,
    interactive: true,
  });

  helpIcon.addEventListener('mouseenter', () => {
    helpIcon.style.transform = 'scale(1.1)';
    helpIcon.style.boxShadow = '0 4px 8px rgba(0,0,0,0.15)';
  });

  helpIcon.addEventListener('mouseleave', () => {
    helpIcon.style.transform = 'scale(1)';
    helpIcon.style.boxShadow = '0 2px 4px rgba(0,0,0,0.1)';
  });
}
