import { html } from 'fxjs/es';

/**
 * 현재 열려있는 업로드 다이얼로그를 닫습니다.
 */
export function closeUploadDialog() {
  const dialog = document.querySelector('dialog.marpple-svg-upload-dialog');
  if (dialog instanceof HTMLDialogElement) {
    dialog.close();
    dialog.remove();
  }
}

/**
 * 칼선 파일 업로드 다이얼로그를 생성하고 표시합니다.
 * @param {Object} params
 * @param {SVGElement} params.lastEditedSvg - 마지막으로 편집된 SVG 요소
 * @param {string} params.up_id - 업로드 ID
 * @param {Function} params.onConfirm - 확인 버튼 클릭 시 호출될 콜백
 * @param {Function} params.onSelectNew - 새 파일 선택 버튼 클릭 시 호출될 콜백
 * @param {Function} params.onCancel - 취소 버튼 클릭 시 호출될 콜백
 */
export function showUploadDialog({ lastEditedSvg, up_id, onConfirm, onSelectNew, onCancel }) {
  // 기존 다이얼로그가 있다면 제거
  closeUploadDialog();

  // 새로운 다이얼로그 생성
  const dialog = document.createElement('dialog');
  dialog.className = 'marpple-svg-upload-dialog';
  dialog.style.cssText = `
    padding: 20px;
    border: none;
    border-radius: 8px;
    background: white;
    box-shadow: 0 0 10px rgba(0,0,0,0.1);
    z-index: 999999;
  `;

  dialog.innerHTML = html`
    <div class="marpple-svg-upload-dialog-content" style="text-align: center;">
      <h3 style="margin-top: 0;">편집된 칼선 파일 발견</h3>
      <p>방금 편집한 칼선 파일을 업로드하시겠습니까?</p>
      <div style="display: flex; gap: 10px; justify-content: center; margin-top: 20px;">
        <button
          class="marpple-svg-upload-dialog-confirm"
          style="padding: 8px 16px; background: #4CAF50; color: white; border: none; border-radius: 4px; cursor: pointer;"
        >
          예, 편집한 파일을 업로드합니다
        </button>
        <button
          class="marpple-svg-upload-dialog-select-new"
          style="padding: 8px 16px; background: #2156FF; color: white; border: none; border-radius: 4px; cursor: pointer;"
        >
          아니오, 새 파일을 선택합니다
        </button>
        <button
          class="marpple-svg-upload-dialog-cancel"
          style="padding: 8px 16px; background: #f5f5f5; border: 1px solid #ddd; border-radius: 4px; cursor: pointer;"
        >
          취소
        </button>
      </div>
    </div>
  `;

  // 다이얼로그를 body에 추가하고 표시
  document.body.appendChild(dialog);
  dialog.showModal();

  // 이벤트 핸들러
  const confirmBtn = dialog.querySelector('.marpple-svg-upload-dialog-confirm');
  const selectNewBtn = dialog.querySelector('.marpple-svg-upload-dialog-select-new');
  const cancelBtn = dialog.querySelector('.marpple-svg-upload-dialog-cancel');

  if (confirmBtn && selectNewBtn && cancelBtn) {
    // 편집된 파일 업로드
    confirmBtn.addEventListener('click', async () => {
      try {
        const svgString = new XMLSerializer().serializeToString(lastEditedSvg);
        const svgBlob = new Blob([svgString], { type: 'image/svg+xml' });
        const file = new File([svgBlob], `칼선-${up_id}.svg`, { type: 'image/svg+xml' });
        $.don_loader_start();
        await onConfirm?.(file);
        $.don_loader_end();
        closeUploadDialog();
      } catch (error) {
        console.error('Error in confirm handler:', error);
        $.don_loader_end();
        closeUploadDialog();
      } finally {
        $.don_loader_end();
      }
    });

    // 새 파일 선택
    selectNewBtn.addEventListener('click', () => {
      closeUploadDialog();
      onSelectNew?.();
    });

    // 취소
    cancelBtn.addEventListener('click', () => {
      closeUploadDialog();
      onCancel?.();
    });
  }
}
