// 상수 정의
import { getLastEditedSvg } from './eventHandlers.js';
import { openModal } from './openModal.js';

export const ZOOM_CONFIG = {
  CROSSHAIR_SIZE: 30,
  CROSSHAIR_GAP: 12,
  INNER_CIRCLE_RADIUS: 10,
  OUTER_CIRCLE_RADIUS: 20,
};

/**
 * 이미지 줌 뷰를 설정합니다.
 * @param {Object} params - 설정에 필요한 파라미터
 * @param {HTMLElement} params.dom - DOM 엘리먼트
 * @param {string} params.svgSelector - SVG 엘리먼트 선택자
 * @param {string} params.canvasSelector - Canvas 엘리먼트 선택자
 * @param {number} params.zoomWidth - 줌 뷰의 너비
 * @param {number} params.zoomHeight - 줌 뷰의 높이
 * @returns {Object} 줌 뷰 컨트롤 객체
 */
export function setupZoomView({ dom, svgSelector, canvasSelector, zoomWidth, zoomHeight }) {
  let isRenderingPaused = false;
  let currentHandlers = null;

  const initialize = () => {
    const svgEl = dom.querySelector(svgSelector);
    const canvas = dom.querySelector(canvasSelector);
    if (!svgEl || !canvas) return null;

    if (!(canvas instanceof HTMLCanvasElement)) {
      throw new Error('canvas is not an instance of HTMLCanvasElement');
    }

    const ctx = canvas.getContext('2d');
    if (!ctx) return null;

    const loadImage = () => {
      const imageObj = new Image();
      const svgData = new XMLSerializer().serializeToString(svgEl);
      const svgBlob = new Blob([svgData], { type: 'image/svg+xml;charset=utf-8' });
      const svgUrl = URL.createObjectURL(svgBlob);

      imageObj.src = svgUrl;
      imageObj.onload = () => {
        URL.revokeObjectURL(svgUrl);
        currentHandlers = setupZoomHandlers(imageObj);
      };
    };

    const drawCrosshair = (centerX, centerY) => {
      ctx.beginPath();
      // 가로선
      ctx.moveTo(centerX - ZOOM_CONFIG.CROSSHAIR_SIZE, centerY);
      ctx.lineTo(centerX - ZOOM_CONFIG.CROSSHAIR_GAP, centerY);
      ctx.moveTo(centerX + ZOOM_CONFIG.CROSSHAIR_GAP, centerY);
      ctx.lineTo(centerX + ZOOM_CONFIG.CROSSHAIR_SIZE, centerY);
      // 세로선
      ctx.moveTo(centerX, centerY - ZOOM_CONFIG.CROSSHAIR_SIZE);
      ctx.lineTo(centerX, centerY - ZOOM_CONFIG.CROSSHAIR_GAP);
      ctx.moveTo(centerX, centerY + ZOOM_CONFIG.CROSSHAIR_GAP);
      ctx.lineTo(centerX, centerY + ZOOM_CONFIG.CROSSHAIR_SIZE);
      ctx.strokeStyle = 'rgba(0, 0, 255, 0.8)';
      ctx.lineWidth = 1.5;
      ctx.stroke();

      // 가이드 원들
      ctx.beginPath();
      ctx.arc(centerX, centerY, ZOOM_CONFIG.INNER_CIRCLE_RADIUS, 0, Math.PI * 2);
      ctx.strokeStyle = 'rgba(0, 0, 255, 0.8)';
      ctx.lineWidth = 1;
      ctx.stroke();

      ctx.beginPath();
      ctx.arc(centerX, centerY, ZOOM_CONFIG.OUTER_CIRCLE_RADIUS, 0, Math.PI * 2);
      ctx.strokeStyle = 'rgba(0, 0, 255, 0.4)';
      ctx.lineWidth = 1;
      ctx.stroke();
    };

    const setupZoomHandlers = (imageObj) => {
      const container = svgEl.closest('.image-container');
      if (!container) return;

      if (!(container instanceof HTMLElement)) {
        throw new Error('container is not an instance of HTMLElement');
      }

      // 이전 이벤트 리스너 제거
      if (currentHandlers) {
        currentHandlers.destroy();
      }

      container.style.cursor = 'crosshair';

      const handleMouseMove = (e) => {
        if (isRenderingPaused) return;

        const rect = container.getBoundingClientRect();
        const computedStyle = window.getComputedStyle(container);
        const paddingLeft = parseFloat(computedStyle.paddingLeft);
        const paddingTop = parseFloat(computedStyle.paddingTop);

        const x = e.clientX - rect.left - paddingLeft;
        const y = e.clientY - rect.top - paddingTop;

        const contentWidth = container.offsetWidth - paddingLeft - parseFloat(computedStyle.paddingRight);
        const contentHeight = container.offsetHeight - paddingTop - parseFloat(computedStyle.paddingBottom);

        const scaleX = imageObj.naturalWidth / contentWidth;
        const scaleY = imageObj.naturalHeight / contentHeight;

        ctx.clearRect(0, 0, canvas.width, canvas.height);

        const pointerX = x * scaleX;
        const pointerY = y * scaleY;

        let sourceX = pointerX - zoomWidth / 2;
        let sourceY = pointerY - zoomHeight / 2;
        let drawX = 0;
        let drawY = 0;
        let sourceWidth = zoomWidth;
        let sourceHeight = zoomHeight;

        // X축 방향 조정
        if (sourceX < 0) {
          drawX = (-sourceX / sourceWidth) * canvas.width;
          sourceWidth += sourceX;
          sourceX = 0;
        } else if (sourceX + sourceWidth > imageObj.naturalWidth) {
          const overflow = sourceX + sourceWidth - imageObj.naturalWidth;
          sourceWidth -= overflow;
        }

        // Y축 방향 조정
        if (sourceY < 0) {
          drawY = (-sourceY / sourceHeight) * canvas.height;
          sourceHeight += sourceY;
          sourceY = 0;
        } else if (sourceY + sourceHeight > imageObj.naturalHeight) {
          const overflow = sourceY + sourceHeight - imageObj.naturalHeight;
          sourceHeight -= overflow;
        }

        ctx.drawImage(
          imageObj,
          sourceX,
          sourceY,
          sourceWidth,
          sourceHeight,
          drawX,
          drawY,
          canvas.width * (sourceWidth / zoomWidth),
          canvas.height * (sourceHeight / zoomHeight),
        );

        drawCrosshair(canvas.width / 2, canvas.height / 2);
      };

      const handleMouseLeave = () => {
        if (!isRenderingPaused) {
          ctx.clearRect(0, 0, canvas.width, canvas.height);
        }
      };

      container.addEventListener('mousemove', handleMouseMove);
      container.addEventListener('mouseleave', handleMouseLeave);

      return {
        destroy: () => {
          container.removeEventListener('mousemove', handleMouseMove);
          container.removeEventListener('mouseleave', handleMouseLeave);
        },
      };
    };

    loadImage();
  };

  // 초기 설정
  initialize();

  return {
    togglePause: () => {
      isRenderingPaused = !isRenderingPaused;
      return isRenderingPaused;
    },
    isPaused: () => isRenderingPaused,
    reSetup: () => {
      initialize();
    },
  };
}

/**
 * 이미지 줌 뷰를 초기화합니다.
 */
export function initializeZoomViews(dom, cutting_line_svg_url, printable_png_url, zoomSize) {
  const zoomConfig = {
    dom,
    svgSelector: '#combined-svg-container svg',
    canvasSelector: '.combined-zoom',
    zoomWidth: zoomSize,
    zoomHeight: zoomSize,
  };

  let zoomView = setupZoomView(zoomConfig);
  let isRenderingPaused = false;
  const toggleButton = dom.querySelector('#zoom-toggle');

  const captureZoomView = (canvas) => {
    const link = document.createElement('a');
    link.download = `zoom-capture-${new Date().getTime()}.png`;
    link.href = canvas.toDataURL('image/png');
    link.click();
  };

  const handleKeyPress = async (e) => {
    if (e.key.toLowerCase() === 'r' || e.key === 'ㄱ') {
      isRenderingPaused = zoomView.togglePause();
      if (toggleButton) {
        toggleButton.style.background = isRenderingPaused ? '#ddd' : '#fff';
      }
    } else if (e.key.toLowerCase() === 'c' || e.key === 'ㅊ') {
      const canvas = dom.querySelector('.combined-zoom');
      if (canvas) {
        captureZoomView(canvas);
      }
    } else if (e.key.toLowerCase() === 'f' || e.key === 'ㄹ') {
      const lastEditedSvg = getLastEditedSvg();

      await openModal(
        lastEditedSvg ? new XMLSerializer().serializeToString(lastEditedSvg) : cutting_line_svg_url,
        printable_png_url,
        dom,
        () => {
          zoomView && zoomView.reSetup();
        },
      );
    }
  };

  dom.addEventListener('keydown', handleKeyPress);

  return {
    zoomView,
    destroy: () => {
      dom.removeEventListener('keydown', handleKeyPress);
      if (zoomView) {
        zoomView = null;
      }
    },
  };
}
