import paper from 'paper';

// 마지막으로 편집된 SVG를 저장할 변수
let lastEditedSvg = null;

/**
 * 마지막으로 편집된 SVG를 반환합니다.
 * @returns {SVGElement | null}
 */
export function getLastEditedSvg() {
  return lastEditedSvg;
}

/**
 * 마지막으로 편집된 SVG를 초기화합니다.
 */
export function clearLastEditedSvg() {
  lastEditedSvg = null;
}

export function setLastEditedSvg(svg) {
  lastEditedSvg = svg;
}

/**
 * 다이얼로그의 이벤트 핸들러를 설정합니다.
 * @param {Object} params - 파라미터 객체
 * @param {HTMLDialogElement} params.dialog - 다이얼로그 요소
 * @param {Function} params.cleanup - 정리 함수
 * @param {Function} params.resolve - Promise resolve 함수
 * @param {Object} params.toolEvents - 툴 이벤트 객체
 * @param {Function} params.onClose - 닫힐 때 호출될 콜백 함수
 */
export function setupEventHandlers({ dialog, cleanup, resolve, toolEvents, onClose }) {
  const saveButton = dialog.querySelector('#save-btn');
  const cancelButton = dialog.querySelector('#cancel-btn');

  if (saveButton) {
    saveButton.addEventListener('click', () => {
      // raster 제거
      const raster = paper.project.getItems({ class: paper.Raster })[0];
      if (raster) {
        raster.remove();
      }

      // rect 요소 제거
      const rects = paper.project.getItems({ _type: 'rectangle' });
      rects.forEach((rect) => rect.remove());

      // 모든 그룹 요소 풀기
      const groups = paper.project.getItems({ type: 'group' });
      groups.forEach((group) => {
        if (group.parent) {
          // 그룹의 자식들을 그룹의 부모로 이동
          while (group.children.length > 0) {
            const child = group.firstChild;
            child.insertAbove(group);
          }
          // 빈 그룹 제거
          group.remove();
        }
      });

      const exportedSvg = /** @type {SVGSVGElement} */ (
        paper.project.exportSVG({ asString: false, bounds: 'content' })
      );

      // viewBox 속성의 쉼표를 공백으로 변경
      if (exportedSvg.hasAttribute('viewBox')) {
        const viewBox = exportedSvg.getAttribute('viewBox');

        if (viewBox) {
          const values = viewBox.split(',').map((v) => v.trim());
          exportedSvg.setAttribute('viewBox', values.join(' '));
        }
      }

      onSaveHandler({ exportedSvg });
      onClose && onClose();

      dialog.close();
      cleanup();
      resolve(exportedSvg);
    });
  }

  if (cancelButton) {
    cancelButton.addEventListener('click', () => handleCancel({ toolEvents, cleanup, resolve }));
  }

  // ESC 키로 닫기 방지
  dialog.addEventListener('cancel', (e) => {
    e.preventDefault();
    handleCancel({ toolEvents, cleanup, resolve });
  });

  // 's' 키를 누르면 저장 버튼 트리거
  dialog.addEventListener('keydown', (e) => {
    if ((e.key.toLowerCase() === 's' || e.key === 'ㄴ') && !e.ctrlKey && !e.metaKey) {
      e.preventDefault();
      if (saveButton instanceof HTMLButtonElement) {
        saveButton.click();
      }
    }
  });
}

/**
 * 취소 처리를 수행합니다.
 * @param {Object} params - 파라미터 객체
 * @param {Object} params.toolEvents - 툴 이벤트 객체
 * @param {Function} params.cleanup - 정리 함수
 * @param {Function} params.resolve - Promise resolve 함수
 */
function handleCancel({ toolEvents, cleanup, resolve }) {
  // 변경사항이 있는 경우 사용자에게 확인
  if (toolEvents && toolEvents.getUndoStackLength && toolEvents.getUndoStackLength() > 0) {
    if (!window.confirm('변경사항이 있습니다. 정말로 취소하시겠습니까?')) {
      return;
    }
  }
  cleanup();
  resolve({ isConfirmed: false });
}

function onSaveHandler({ exportedSvg }) {
  const container = document.querySelector('#combined-svg-container');
  if (container) {
    const existingSvg = container.querySelector('svg');
    if (existingSvg) {
      setLastEditedSvg(exportedSvg);

      // 기존 SVG의 path와 새로운 SVG의 path 가져오기
      const existingPath = existingSvg.querySelector('path');
      const newPath = exportedSvg.querySelector('path');

      if (existingPath && newPath) {
        const newPathData = newPath.getAttribute('d');
        if (newPathData) {
          // path 데이터만 교체
          existingPath.setAttribute('d', newPathData);
        }
      }
    }
  }
}
