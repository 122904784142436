import { injectDpiToPngBlob } from './image.js';
import { UtilS } from '../../../../../Util/S/Function/module/UtilS.js';

/**
 * Browser 에서 Blob을 다운로드합니다.
 */
export function downloadBlob(blob, fileName) {
  const url = window.URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = url;
  link.download = fileName;
  document.body.appendChild(link);
  link.click();
  window.URL.revokeObjectURL(url);
  document.body.removeChild(link);
}

/**
 * PNG 파일을 다운로드합니다.
 */
export async function downloadPngFile(url, fileName, dpi = 72) {
  const response = await fetch(UtilS.ensureProtocol(url));
  const originalBlob = await response.blob();
  const dpiBlob = await injectDpiToPngBlob(originalBlob, dpi);
  await downloadBlob(dpiBlob, fileName);
}

/**
 * 파일 업로드를 처리합니다.
 */
export async function handleFileUpload({ accept, onFileSelect }) {
  const input = document.createElement('input');
  input.type = 'file';
  input.accept = accept;

  input.onchange = async (e) => {
    const target = e.target;
    if (!target || !target.files) return;

    const file = target.files[0];
    if (!file) return;

    await onFileSelect(file);
  };

  input.click();
}

/**
 * SVG 파일을 다운로드합니다.
 */
export async function downloadSvgFileFromSvgEl(svgElement, fileName) {
  if (!svgElement) throw new Error('SVG element not found');

  const serializer = new XMLSerializer();
  const svgString = serializer.serializeToString(svgElement);
  const blob = new Blob([svgString], { type: 'image/svg+xml;charset=utf-8' });

  await downloadBlob(blob, fileName);
}
