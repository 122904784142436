import tippy from 'tippy.js';
import 'tippy.js/dist/tippy.css';

/**
 * Tooltip 설정을 초기화합니다.
 */
export function initializeTooltips(dom) {
  const zoomToggleBtn = dom.querySelector('#zoom-toggle');
  const captureBtn = dom.querySelector('#capture-btn');
  const editCutBtn = dom.querySelector('#edit-cut-btn');
  const uploadBtn = dom.querySelector('#upload-btn');
  const resetBtn = dom.querySelector('.reset-cutfile-btn');
  const commonTippyConfig = {
    placement: /** @type {'bottom'} */ ('bottom'),
    theme: 'light',
    animation: 'shift-away',
    arrow: true,
    delay: /** @type {[number, number]} */ ([100, 0]),
    zIndex: 999999,
    interactive: true,
    appendTo: (ref) => document.querySelector('.swal2-container') || ref.parentElement || document.body,
  };

  if (zoomToggleBtn) {
    tippy(zoomToggleBtn, {
      ...commonTippyConfig,
      content: '확대 중에 키보드의 R 키를 누르면 장면이 고정됩니다. 다시 누르면 고정이 풀립니다.',
    });
  }

  if (captureBtn) {
    tippy(captureBtn, {
      ...commonTippyConfig,
      content: '확대 중에 키보드의 C 키를 누르면 현재 화면 캡쳐됩니다.',
    });
  }

  if (editCutBtn) {
    tippy(editCutBtn, {
      ...commonTippyConfig,
      content: '키보드의 F 키를 누르면 칼선 편집기가 열립니다.',
    });
  }

  if (uploadBtn) {
    tippy(uploadBtn, {
      ...commonTippyConfig,
      content: '키보드의 U 키를 누르면 칼선 업로드가 열립니다.',
    });
  }

  if (resetBtn) {
    tippy(resetBtn, {
      ...commonTippyConfig,
      content: '고객 주문 상태로 칼선이 초기화 됩니다.',
    });
  }
}
