/**
 * 길이 단위를 픽셀로 변환합니다 (300 DPI 기준).
 * @param {string} value - 길이 값 (예: "10cm", "20mm", "1in")
 * @returns {number | null} 픽셀 값
 */
export function convertToLengthUnitToPixels(value) {
  if (!value) return null;

  // 숫자만 있는 경우
  if (!Number.isNaN(Number(value))) return Number(value);

  const match = value.match(/^([\d.]+)([a-z]*)$/i);
  if (!match) return null;

  const num = parseFloat(match[1]);
  const unit = match[2].toLowerCase();

  // 300dpi 기준으로 단위 변환
  const DPI = 300;
  switch (unit) {
    case 'mm':
      return (num * DPI) / 25.4;
    case 'cm':
      return (num * DPI) / 2.54;
    case 'in':
      return num * DPI;
    case 'pt':
      return (num * DPI) / 72;
    case 'pc':
      return (num * DPI) / 6;
    case 'px':
      return num;
    default:
      return num;
  }
}
