/**
 * 에러 UI를 표시합니다.
 * @param {Object} params - 파라미터 객체
 * @param {HTMLElement} params.dialogContent - 다이얼로그 컨텐츠 요소
 * @param {string} params.errorMessage - 에러 메시지
 * @param {Function} params.cleanup - 정리 함수
 * @param {Function} params.resolve - Promise resolve 함수
 * @param {Error} params.error - 에러 객체
 * @param {string} params.originalContent - 원래 컨텐츠
 * @param {Function} params.retryInitialization - 초기화 재시도 함수
 */
export function showErrorUI({
  dialogContent,
  errorMessage,
  cleanup,
  resolve,
  error,
  originalContent,
  retryInitialization,
}) {
  dialogContent.innerHTML = `
    <div style="height: 100%; display: flex; flex-direction: column; justify-content: center; align-items: center; padding: 20px; text-align: center;">
      <div style="color: #d32f2f; margin-bottom: 20px;">
        <svg width="48" height="48" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2">
          <circle cx="12" cy="12" r="10"></circle>
          <path d="M12 5v8" stroke-width="3"></path>
          <circle cx="12" cy="17" r="1"></circle>
        </svg>
      </div>
      <h2 style="color: #d32f2f; margin: 0 0 16px;">오류 발생</h2>
      <p style="margin: 0 0 24px; color: #666;">${errorMessage}</p>
      <div style="display: flex; gap: 8px;">
        ${
          retryInitialization
            ? `<button id="error-retry-btn" style="padding: 8px 16px; background: #2156FF; color: white; border: none; border-radius: 4px; cursor: pointer;">
                다시 시도
              </button>`
            : ''
        }
        <button id="error-close-btn" style="padding: 8px 16px; background: #f5f5f5; border: 1px solid #ddd; border-radius: 4px; cursor: pointer;">
          닫기
        </button>
      </div>
    </div>
  `;

  const closeButton = dialogContent.querySelector('#error-close-btn');
  const retryButton = dialogContent.querySelector('#error-retry-btn');

  if (closeButton) {
    closeButton.addEventListener('click', () => {
      cleanup();
      resolve({ isConfirmed: false, error });
    });
  }

  if (retryButton && retryInitialization && originalContent) {
    retryButton.addEventListener('click', async () => {
      // 원래 컨텐츠로 복원
      dialogContent.innerHTML = originalContent;

      // 다시 초기화 시도
      try {
        await retryInitialization();
      } catch (retryError) {
        // 재시도 실패 시 다시 에러 메시지 표시
        console.error('Error retrying initialization:', retryError);
        showErrorUI({
          dialogContent,
          errorMessage: '다시 시도하는 중 오류가 발생했습니다.',
          cleanup,
          resolve,
          error: retryError,
        });
      }
    });
  }
}

/**
 * 에러 메시지를 사용자 친화적으로 변환합니다.
 * @param {Error} error - 에러 객체
 * @returns {string} 사용자 친화적인 에러 메시지
 */
export function getUserFriendlyErrorMessage(error) {
  return error.message === '편집기 초기화에 실패했습니다.' ||
    error.message === '편집기 컨트롤 초기화에 실패했습니다.'
    ? error.message
    : '칼선 편집기를 불러오는 중 오류가 발생했습니다.';
}
