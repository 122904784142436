import { html } from 'fxjs/es';

/**
 * 칼선 편집기 다이얼로그를 생성합니다.
 * @returns {HTMLDialogElement} 생성된 다이얼로그 요소
 */
export function createEditorDialog() {
  const dialog = document.createElement('dialog');
  dialog.style.cssText = `
    max-width: 1660px;
    width: 90vw;
    height: 90vh;
    padding: 0;
    border: none;
    border-radius: 8px;
    background: white;
    box-shadow: 0 0 10px rgba(0,0,0,0.1);
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin: 0;
    opacity: 0;
    transition: opacity 0.3s ease;
  `;

  dialog.innerHTML = html`
    <div style="height: 100%; display: flex; flex-direction: column;">
      <div
        style="padding: 16px; border-bottom: 1px solid #ddd; display: flex; justify-content: space-between; align-items: center;"
      >
        <div style="display: flex; align-items: center; gap: 8px;">
          <h2 style="margin: 0; font-size: 18px;">칼선 편집기</h2>
          <div
            id="help-icon"
            style="cursor: pointer; color: #2156FF; background: white; padding: 4px; border-radius: 50%; box-shadow: 0 2px 4px rgba(0,0,0,0.1); display: flex; align-items: center; justify-content: center; width: 24px; height: 24px; transition: all 0.2s ease;"
          >
            <svg
              width="16"
              height="16"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            >
              <circle cx="12" cy="12" r="10"></circle>
              <line x1="12" y1="16" x2="12" y2="16"></line>
              <path d="M12 12a2 2 0 1 0 0-4"></path>
              <path d="M12 16v1"></path>
            </svg>
          </div>
        </div>
        <div>
          <button
            id="save-btn"
            style="padding: 8px 16px; margin-right: 8px; background: #4CAF50; color: white; border: none; border-radius: 4px; cursor: pointer;"
          >
            임시 반영
          </button>
          <button
            id="cancel-btn"
            style="padding: 8px 16px; background: #f5f5f5; border: 1px solid #ddd; border-radius: 4px; cursor: pointer;"
          >
            취소
          </button>
        </div>
      </div>
      <div
        style="flex: 1; position: relative; display: flex; justify-content: center; align-items: center; padding: 16px;"
      >
        <canvas
          id="cut-editor"
          style="width: calc(100% - 32px); height: calc(100% - 32px); border: 1px solid #ddd; outline: none;"
        ></canvas>
      </div>
    </div>
  `;

  return dialog;
}
